import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    pagination: {}
};

export const workSpaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const { setPagination } =
workSpaceSlice.actions;

export default workSpaceSlice.reducer;
