import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import employee from "./employee";
import engineer from "./engineer";
import leaves from "./leaves";
import leaveType from "./leaveType";
import projects from "./projects";
import dailyTask from "./dailyTask";
import assignedProjects from "./assignedProjects";
import leaveStatus from "./leaveStatus";
import designation from "./designation";
import techSkill from "./techSkill";
import projectCategory from "./projectCategory";
import role from "./role";
import quickLinks from "./quickLinks";
import holidayList from "./holidayList";
import compensationTime from "./compensationTime";
import monthlyReport from "./monthlyReport";
import receiverEmail from "./receiverEmail";
import salarySlip from "./salarySlip";
import alternativeWorkingDayList from "./alternativeWorkingDayList";
import workspace from "./workspace"
import missPunch from './missPunch'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    employee,
    engineer,
    leaves,
    leaveType,
    projects,
    dailyTask,
    assignedProjects,
    leaveStatus,
    designation,
    techSkill,
    projectCategory,
    role,
    quickLinks,
    holidayList,
    compensationTime,
    receiverEmail,
    monthlyReport,
    salarySlip,
    alternativeWorkingDayList,
    workspace,
    missPunch,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
