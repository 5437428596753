import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    pagination: {}
};

export const missPunchSlice = createSlice({
  name: "missPunch",
  initialState,
  reducers: {
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
});

export const { setPagination } =
missPunchSlice.actions;

export default missPunchSlice.reducer;
